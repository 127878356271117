import React, { createContext, useContext, useEffect, useState } from "react";
import EmissaoAutomatica, {
    EmissaoAutomaticaStatus,
} from "../core/EmissaoAutomatica";
import MesRef from "../core/MesRef";
import EmissaoAutomaticaRepository, {
    FilterParams,
} from "../repositories/EmissaoAutomatica";
import AuthContext from "./AuthContext";

type ContextType = {
    emissoesAutomaticas: Array<EmissaoAutomatica>;
    getByStatus(status: EmissaoAutomaticaStatus): EmissaoAutomatica[];
    showEmissaoAutomatica(emissao: EmissaoAutomatica): void;
    hideEmissaoAutomatica(emissao: EmissaoAutomatica): void;
    hide: boolean;
    setHide(hide: boolean): void;
    idsToHide: Array<string>;
    mesRef: MesRef;
    setMesRef(mesRef: MesRef): void;
    deleteByUuid(uuid: string): Promise<void>;
    toggleDelete(): void;
    enabledDelete: boolean;
};

const defaultValue: ContextType = {
    emissoesAutomaticas: [],
    getByStatus: () => [],
    showEmissaoAutomatica: () => {},
    hideEmissaoAutomatica: () => {},
    hide: true,
    setHide: (hide: boolean) => {},
    idsToHide: [],
    mesRef: new MesRef(new Date()),
    setMesRef: () => {},
    deleteByUuid: async () => {},
    toggleDelete: () => {},
    enabledDelete: false
};

const EmissoesAutomaticasContext = createContext<ContextType>(defaultValue);

export const EmissoesAutomaticasProvider = ({
    children,
    emissaoAutomaticaRepository,
}: {
    children: React.ReactNode;
    emissaoAutomaticaRepository: EmissaoAutomaticaRepository;
}) => {
    const { token } = useContext(AuthContext);
    
    const [enabledDelete, setEnabledDelete] = useState(false);

    const [hide, setHide] = useState<boolean>(true);
    const [idsToHide, setIdsToHide] = useState<string[]>([]);
    const [emissoesAutomaticas, setEmissoesAutomaticas] = useState<
        EmissaoAutomatica[]
    >([]);

    const [mesRef, setMesRef] = useState<MesRef>(new MesRef(new Date()));

    useEffect(() => {
        if (!token) {
            setEmissoesAutomaticas([]);
            return;
        }
        const filterParams: FilterParams = {
            mesRef,
        };
        emissaoAutomaticaRepository
            .scan(token, filterParams)
            .then(setEmissoesAutomaticas);
    }, [emissaoAutomaticaRepository, token, mesRef]);

    useEffect(() => {
        const hideIdsString = localStorage.getItem("@emissorHideIds");
        const hideIds = (hideIdsString || "").split(".");
        setIdsToHide(hideIds);
    }, []);

    const toggleDelete = () => setEnabledDelete(!enabledDelete);

    const hideEmissaoAutomatica = (emissaoAutomatica: EmissaoAutomatica) => {
        const newIdsToHide = [...idsToHide].filter(
            (id) => id !== emissaoAutomatica.uuid
        );
        newIdsToHide.push(emissaoAutomatica.uuid);
        const newIdsToHideString = newIdsToHide.join(".");
        localStorage.setItem("@emissorHideIds", newIdsToHideString);
        setIdsToHide(newIdsToHide);
    };

    const showEmissaoAutomatica = (emissaoAutomatica: EmissaoAutomatica) => {
        const newIdsToHide = [...idsToHide].filter(
            (id) => id !== emissaoAutomatica.uuid
        );
        const newIdsToHideString = newIdsToHide.join(".");
        localStorage.setItem("@emissorHideIds", newIdsToHideString);
        setIdsToHide(newIdsToHide);
    };

    const getByStatus = (
        status: EmissaoAutomaticaStatus
    ): EmissaoAutomatica[] => {
        return emissoesAutomaticas
            .filter((emissaoAutom) => emissaoAutom.status === status)
            .sort((a, b) => {
                if (a.error && b.error) return 0;
                if (a.error) return -1;
                if (b.error) return 1;
                return 0;
            })
            .sort(
                (a, b) =>
                    b.createdAt.date.getTime() - a.createdAt.date.getTime()
            );
    };

    const getEmissaoAutomaticaById = (uuid: string) => {
        const filterById = emissoesAutomaticas.filter(
            (emissao) => emissao.uuid === uuid
        );
        if (filterById.length < 1)
            throw new Error("EmissaoAutomatica not found!");
        return filterById[0];
    };

    const deleteByUuid = async (uuid: string): Promise<void> => {
        const emissaoAutomatica = getEmissaoAutomaticaById(uuid);
        return emissaoAutomaticaRepository
            .delete(emissaoAutomatica, token)
            .then(() => {
                const newEmissoesAutomaticas = [...emissoesAutomaticas].filter(ea => ea.uuid !== uuid);
                setEmissoesAutomaticas(newEmissoesAutomaticas);
            });
    };

    return (
        <EmissoesAutomaticasContext.Provider
            value={{
                emissoesAutomaticas,
                getByStatus,
                hideEmissaoAutomatica,
                showEmissaoAutomatica,
                hide,
                setHide,
                idsToHide,
                setMesRef,
                mesRef,
                deleteByUuid,
                enabledDelete,
                toggleDelete
            }}
        >
            {children}
        </EmissoesAutomaticasContext.Provider>
    );
};

export default EmissoesAutomaticasContext;
