import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { EmissoesAutomaticasProvider } from "./contexts/EmissoesAutomaticasContext";
import EmissaoAutomaticaRepositoryAxios from "./repositories/EmissaoAutomaticaAxios";
import { LeiturasProvider } from "./contexts/LeiturasContext";
import PlinApiGatewayAxios from "./gateway/PlinApiAxios";
import { AuthProvider } from "./contexts/AuthContext";

const emissaoAutomaticaRepository = new EmissaoAutomaticaRepositoryAxios();
const plinApiGateway = new PlinApiGatewayAxios();

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <AuthProvider plinApiGateway={plinApiGateway}>
            <EmissoesAutomaticasProvider
                emissaoAutomaticaRepository={emissaoAutomaticaRepository}
            >
                <LeiturasProvider plinApiGateway={plinApiGateway}>
                    <App />
                </LeiturasProvider>
            </EmissoesAutomaticasProvider>
        </AuthProvider>
    </React.StrictMode>
);
