import DateRef from "./DateRef";

export default class EmissaoAutomatica {
    constructor(
        readonly uuid: string,
        readonly mesRef: DateRef,
        readonly status: EmissaoAutomaticaStatus,
        readonly error: boolean,
        readonly leituraUuid: string,
        readonly cobrancaUuid: string | null,
        readonly createdAt: DateRef
    ) {}
}

export enum EmissaoAutomaticaStatus {
    INICIAL = "INICIAL",
    GERADA = "GERADA",
    INVALIDA = "INVALIDA",
    ENVIADA = "ENVIADA",
}

export const stringToEmissaoAutomaticaStatus = (
    str: string
): EmissaoAutomaticaStatus => {
    switch (str) {
        case EmissaoAutomaticaStatus.ENVIADA:
            return EmissaoAutomaticaStatus.ENVIADA;
            break;
        case EmissaoAutomaticaStatus.GERADA:
            return EmissaoAutomaticaStatus.GERADA;
            break;
        case EmissaoAutomaticaStatus.INICIAL:
            return EmissaoAutomaticaStatus.INICIAL;
            break;
        case EmissaoAutomaticaStatus.INVALIDA:
            return EmissaoAutomaticaStatus.INVALIDA;
            break;
        default:
            throw new Error(
                "EmissaoAutomatica error: EmissaoAutomaticaStatus invalid conversion."
            );
            break;
    }
};
