import { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import EmissoesAutomaticasContext from "../../contexts/EmissoesAutomaticasContext";
import MesRef from "../../core/MesRef";
import styles from "./Header.module.scss";

const Header = () => {
    const { fullLogin, logout, token } = useContext(AuthContext);
    const { hide, setHide, mesRef, setMesRef, enabledDelete, toggleDelete } =
        useContext(EmissoesAutomaticasContext);

    const getMesAndAnoFromMesRef = (mesRef: MesRef): [string, string] => {
        if (!mesRef) return ["", ""];
        const parts = mesRef.toString().split("-");
        return [parts[0], parts[1]];
    };

    const [ano, setAno] = useState(getMesAndAnoFromMesRef(mesRef)[0]);
    const [mes, setMes] = useState(getMesAndAnoFromMesRef(mesRef)[1]);

    useEffect(() => {
        if (!ano || !mes) return;
        setMesRef(MesRef.create(`${ano}-${mes}-01`));
    }, [ano, mes, setMesRef]);

    const handleLoginLogout = async () => {
        if (!token) {
            await fullLogin();
            return;
        }
        logout();
    };

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <button
                    className={styles.hideButton}
                    onClick={() => setHide(!hide)}
                >
                    {hide ? "Mostrar ocultos" : "Esconder"}
                </button>
                <h1 className={styles.h1}>Emissor Automático</h1>
                <button
                    onClick={() => handleLoginLogout()}
                    className={styles.loginButton}
                >
                    {token ? "Logout" : "Login"}
                </button>
            </div>
            <div className={styles.row} style={{ justifyContent: 'space-between' }}>
                <button
                    onClick={() => toggleDelete()}
                >
                    {!enabledDelete ? "Deletar" : "Cancelar"}
                </button>
                <div>
                    <select
                        onChange={(ev) => setMes(ev.target.value)}
                        defaultValue={mes}
                    >
                        <option value="01">Janeiro</option>
                        <option value="02">Fevereiro</option>
                        <option value="03">Março</option>
                        <option value="04">Abril</option>
                        <option value="05">Maio</option>
                        <option value="06">Junho</option>
                        <option value="07">Julho</option>
                        <option value="08">Agosto</option>
                        <option value="09">Setembro</option>
                        <option value="10">Outubro</option>
                        <option value="11">Novembro</option>
                        <option value="12">Dezembro</option>
                    </select>
                    <select
                        onChange={(ev) => setAno(ev.target.value)}
                        defaultValue={ano}
                    >
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                    </select>
                </div>
                <div style={{ width: '120px' }} />
            </div>
        </div>
    );
};

export default Header;
