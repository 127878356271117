import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useContext, useEffect, useState } from "react";
import EmissoesAutomaticasContext from "../../contexts/EmissoesAutomaticasContext";
import LeiturasContext from "../../contexts/LeiturasContext";

import EmissaoAutomatica from "../../core/EmissaoAutomatica";
import { Leitura } from "../../gateway/PlinApi";
import styles from "./Card.module.scss";

const Card = ({ emissao }: { emissao: EmissaoAutomatica }) => {
    const { idsToHide, hide, enabledDelete } = useContext(
        EmissoesAutomaticasContext
    );

    const isCardHide = () => idsToHide.includes(emissao.uuid);

    if (isCardHide() && hide) return <React.Fragment />;

    return (
        <div
            className={styles.container}
            style={{ opacity: isCardHide() ? "0.5" : "1" }}
        >
            <DadosCliente leituraUuid={emissao.leituraUuid} />
            <ErrorBadge error={emissao.error} />
            <small>Execução feita em:</small>
            <span className={styles.createdAt}>
                {formatDate(emissao.createdAt.date)}
            </span>
            <small className={styles.mesRef}>
                Mês Ref.: {formatMesRef(emissao.mesRef.date)}
            </small>
            <div className={styles.buttons}>
                <LeituraButton leituraUuid={emissao.leituraUuid} />
                <CobrancaButton cobrancaUuid={emissao.cobrancaUuid} />
                <EsconderButton emissao={emissao} />
            </div>
            {enabledDelete && (
                <div className={styles.buttons}>
                    <DeletarButton emissao={emissao} />
                </div>
            )}
        </div>
    );
};

const DadosCliente = ({ leituraUuid }: { leituraUuid: string }) => {
    const { getLeituraById, openClienteById } = useContext(LeiturasContext);
    const [leitura, setLeitura] = useState<Leitura>({
        id: "",
        client: {
            cnpj: "",
            id: "",
            razao_social: "...",
            uc: "...",
        },
    });

    useEffect(() => {
        getLeituraById(leituraUuid)
            .then(setLeitura)
            .catch((e) =>
                setLeitura({
                    id: "",
                    client: {
                        cnpj: "",
                        id: "",
                        razao_social: "ERROR",
                        uc: "ERROR",
                    },
                })
            );
    }, [getLeituraById, leituraUuid]);

    return (
        <div className={styles.dadosCliente}>
            <span
                onClick={() => openClienteById(leitura.client.id)}
            >{`(${leitura.client.uc}) ${leitura.client.razao_social}`}</span>
        </div>
    );
};

const DeletarButton = ({ emissao }: { emissao: EmissaoAutomatica }) => {
    const { deleteByUuid } = useContext(EmissoesAutomaticasContext);

    return (
        <input
            type="button"
            value={"Deletar"}
            style={{
                alignSelf: "flex-end",
                marginLeft: "auto",
            }}
            onClick={() => deleteByUuid(emissao.uuid)}
        />
    );
};

const EsconderButton = ({ emissao }: { emissao: EmissaoAutomatica }) => {
    const { hideEmissaoAutomatica, showEmissaoAutomatica, idsToHide } =
        useContext(EmissoesAutomaticasContext);

    const isHide = () => idsToHide.includes(emissao.uuid);

    const handleClick = () => {
        if (isHide()) {
            showEmissaoAutomatica(emissao);
        } else {
            hideEmissaoAutomatica(emissao);
        }
    };

    return (
        <input
            type="button"
            value={!isHide() ? "Esconder" : "Mostrar"}
            style={{
                alignSelf: "flex-end",
                marginLeft: "auto",
            }}
            onClick={() => handleClick()}
        />
    );
};

const LeituraButton = ({ leituraUuid }: { leituraUuid: string }) => {
    const { openLeituraById } = useContext(LeiturasContext);
    return (
        <input
            type="button"
            value="Leitura"
            onClick={() => openLeituraById(leituraUuid)}
        />
    );
};

const CobrancaButton = ({ cobrancaUuid }: { cobrancaUuid: string | null }) => {
    const { openCobrancaById } = useContext(LeiturasContext);
    return (
        <input
            type="button"
            value="Cobrança"
            onClick={
                cobrancaUuid ? () => openCobrancaById(cobrancaUuid) : () => {}
            }
            style={{
                cursor: cobrancaUuid ? "pointer" : "not-allowed",
            }}
            disabled={!cobrancaUuid}
        />
    );
};

const ErrorBadge = ({ error }: { error: boolean }) => {
    return (
        <div
            className={styles.errorIndicator}
            style={{ opacity: error ? 1 : 0 }}
        >
            ERROR
        </div>
    );
};

const formatDate = (date: Date) => format(date, "dd/MM/yyyy");

const formatMesRef = (date: Date) =>
    format(date, "MMMM 'de' yyyy", {
        locale: ptBR,
    });

export default Card;
