import { useContext } from "react";
import styles from "./App.module.scss";
import Card from "./components/card/Card";
import Column from "./components/column/Column";
import Header from "./components/header/Header";
import EmissoesAutomaticasContext from "./contexts/EmissoesAutomaticasContext";
import { EmissaoAutomaticaStatus } from "./core/EmissaoAutomatica";

function App() {
    const { getByStatus } = useContext(EmissoesAutomaticasContext);
    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.columns}>
                <Column label="Inicial" color="#565264">
                    {getByStatus(EmissaoAutomaticaStatus.INICIAL).map(
                        (emissao) => (
                            <Card key={emissao.uuid} emissao={emissao} />
                        )
                    )}
                </Column>
                <Column label="Gerada" color="#A3C4BC">
                    {getByStatus(EmissaoAutomaticaStatus.GERADA).map(
                        (emissao) => (
                            <Card key={emissao.uuid} emissao={emissao} />
                        )
                    )}
                </Column>
                <Column label="Inválida" color="#F2DDA4">
                    {getByStatus(EmissaoAutomaticaStatus.INVALIDA).map(
                        (emissao) => (
                            <Card key={emissao.uuid} emissao={emissao} />
                        )
                    )}
                </Column>
                <Column label="Enviada" color="#BFD7B5">
                    {getByStatus(EmissaoAutomaticaStatus.ENVIADA).map(
                        (emissao) => (
                            <Card key={emissao.uuid} emissao={emissao} />
                        )
                    )}
                </Column>
            </div>
        </div>
    );
}

export default App;
