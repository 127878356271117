/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";

import PlinApiGateway from "../gateway/PlinApi";

type ContextType = {
    token: string;
    login(user: string, password: string): Promise<void>;
    logout(): void;
    fullLogin(): Promise<void>
};

const defaultValue: ContextType = {
    token: "",
    login: async () => {},
    logout: () => {},
    fullLogin: async () => {},
};

const AuthContext = createContext<ContextType>(defaultValue);

export const AuthProvider = ({
    children,
    plinApiGateway,
}: {
    children: React.ReactNode;
    plinApiGateway: PlinApiGateway;
}) => {
    const [token, setToken] = useState("");

    const logout = (): void => {
        setToken("");
        localStorage.removeItem("@emissorToken");
    };

    const login = async (user: string, password: string): Promise<void> => {
        const data = await plinApiGateway.login(user, password);
        setToken(data.access_token);
        localStorage.setItem("@emissorToken", data.access_token);
    };

    useEffect(() => {
        const localToken = localStorage.getItem("@emissorToken");
        if (localToken) {
            setToken(localToken);
        }
    }, []);

    const fullLogin = async () => {
        if (!token) {
            const user = window.prompt("Email")
            const pass = window.prompt("Password")
            if (user && pass) {
                await login(user, pass);
            }
        }
    };

    return (
        <AuthContext.Provider
            value={{
                login,
                token,
                logout,
                fullLogin
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
