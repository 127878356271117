import axios, { AxiosInstance } from "axios";
import DateRef from "../core/DateRef";

import EmissaoAutomatica, {
    stringToEmissaoAutomaticaStatus,
} from "../core/EmissaoAutomatica";
import MesRef from "../core/MesRef";
import EmissaoAutomaticaRepository, { FilterParams } from "./EmissaoAutomatica";

export default class EmissaoAutomaticaRepositoryAxios
    implements EmissaoAutomaticaRepository
{
    axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL_EMISSOR,
        });
    }

    async scan(token: string, filterParams: FilterParams): Promise<EmissaoAutomatica[]> {
        return this.axios
            .get("/emissao-automatica", {
                headers: {
                    authorization: `Bearer ${token}`
                },
                params: {
                    mesRef: filterParams.mesRef?.toString()
                }
            })
            .then(({ data }: { data: ScanResponse }) => {
                return data.map(
                    (item) =>
                        new EmissaoAutomatica(
                            item.uuid,
                            MesRef.create(item.mesRef),
                            stringToEmissaoAutomaticaStatus(item.status),
                            item.error,
                            item.leituraUuid,
                            item.cobrancaUuid || null,
                            DateRef.create(item.createdAt)
                        )
                );
            });
    }

    async delete(emissaoAutomatica: EmissaoAutomatica, token: string): Promise<EmissaoAutomatica> {
        return this.axios
            .delete("/emissao-automatica", {
                params: {
                    uuid: emissaoAutomatica.uuid
                },
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            .then(() => emissaoAutomatica)
    }
}

type ScanResponse = Array<{
    uuid: string;
    mesRef: string;
    status: string;
    error: boolean;
    leituraUuid: string;
    cobrancaUuid: string;
    createdAt: string;
}>;
