import React, { createContext, useContext } from "react";

import PlinApiGateway, { Leitura } from "../gateway/PlinApi";
import AuthContext from "./AuthContext";

type ContextType = {
    getLeituraById(id: string): Promise<Leitura>;
    openLeituraById(id: string): void;
    openCobrancaById(id: string): void;
    openClienteById(id: string): void;
};

const defaultValue: ContextType = {
    getLeituraById: async () => ({
        id: "",
        client: {
            cnpj: "",
            id: "",
            razao_social: "",
            uc: "",
        },
    }),
    openLeituraById: (id: string) => {},
    openCobrancaById: (id: string) => {},
    openClienteById: (id: string) => {},
};

const LeiturasContext = createContext<ContextType>(defaultValue);

export const LeiturasProvider = ({
    children,
    plinApiGateway,
}: {
    children: React.ReactNode;
    plinApiGateway: PlinApiGateway;
}) => {
    const {
        token
    } = useContext(AuthContext);

    const getLeituraById = async (id: string): Promise<Leitura> => {
        if (!token) {
            const empty: Leitura = {
                client: {
                    cnpj: '',
                    id: '',
                    razao_social: '',
                    uc: ''
                },
                id: ''
            };
            return empty;
        }
        return plinApiGateway.getLeitura(id, token);
    };

    const openLeituraById = (id: string) => window.open(`https://painel.plinenergia.com.br/leitura-especifica?leituraId=${id}`);
    const openCobrancaById = (id: string) => window.open(`https://painel.plinenergia.com.br/cobranca-especifica?cobrancaId=${id}`);
    const openClienteById = (id: string) => window.open(`https://painel.plinenergia.com.br/cliente-especifico?clientId=${id}`);

    return (
        <LeiturasContext.Provider
            value={{
                getLeituraById,
                openClienteById,
                openCobrancaById,
                openLeituraById
            }}
        >
            {children}
        </LeiturasContext.Provider>
    );
};

export default LeiturasContext;
