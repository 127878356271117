export default class DateRef {
    constructor(readonly date: Date) {}

    static create(dateString: string) {
        this.verifyDateStringFormat(dateString)
        const date = new Date(`${dateString}T12:00:00.000Z`)
        if (isNaN(date.getTime())) this.throwError('Data inválida!')
        return new DateRef(date)
    }

    private static verifyDateStringFormat(dateString: string) {
        const regex = /^[\d]{4}(\-[\d]{2}){2}$/
        const matchResult = dateString.match(regex)
        if (!matchResult) this.throwError('Formato inválido!')
    }

    private static throwError(msg: string) {
        throw new Error(`Erro ao tentar criar DateRef: ${msg}`)
    }

    toString() {
        const isoString = this.date.toISOString()
        return isoString.split('T')[0]
    }
}
