import axios, { AxiosInstance } from "axios";
import { ISetupCache, setupCache } from "axios-cache-adapter";

import PlinApiGateway, { AuthResponse, Leitura } from "./PlinApi";

export default class PlinApiGatewayAxios implements PlinApiGateway {
    axios: AxiosInstance;
    cache: ISetupCache;

    constructor() {
        this.cache = setupCache({
            maxAge: 1000 * 60 * 60 * 24,
        });
        this.axios = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL_API,
            adapter: this.cache.adapter,
        });
    }

    async login(user: string, password: string): Promise<AuthResponse> {
        return this.axios
            .post(`auth/login`, {
                email: user,
                password
            })
            .then(({ data }) => data as AuthResponse);
    }

    async getLeitura(leituraId: string, token: string): Promise<Leitura> {
        return this.axios
            .get(`admin/energy-reads/${leituraId}`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            .then(({ data }) => data as Leitura);
    }
}
