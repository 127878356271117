import React from "react";
import styles from "./Column.module.scss";

type ColumnsProps = {
    children: React.ReactNode;
    label: string;
    color: string;
    fontColor?: string;
};

const Column = ({
    children,
    label,
    color,
    fontColor = "#000",
}: ColumnsProps) => {
    const childrenLength = () => (children as Array<any>).length;
    return (
        <div className={styles.container}>
            <div
                className={styles.header}
                style={{ color: fontColor, backgroundColor: color }}
            >
                <h2>{label}</h2>
                <small>({childrenLength()})</small>
            </div>
            <div className={styles.children}>{children}</div>
        </div>
    );
};

export default Column;
